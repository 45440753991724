import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-style";
import queryString from "query-string";
import LoadingSpinner from "../../common/loading_spinner";
import ToggleSwitch from "../../common/toggle_switch";
import PillboxToggles from "../Components/PillboxToggles";
import BugsOutline from "../../icons/bugs_outline";
import CargoIcon from "../../icons/cargo_icon";
import ChecklistIcon from "../../icons/checklist_icon";
import EmptyEOD from "../../icons/performance_bio/EmptyEOD";
import chartPlugins, { labelFormatter } from "./chartPlugins";
import {
  ALL,
  BUGS,
  TEST_STEPS,
  USER_ID,
  PROJECT_ID,
  TEAM_ID,
} from "../constants";

ChartJS.register(CategoryScale, BarElement, LinearScale, Tooltip, Legend);

const BugStepsDualBreakdown = (props) => {
  const { bugs, users, loading, parentFilterValue, queryParams, error } = props;
  const [dataToShow, setDataToShow] = useState({
    [BUGS]: true,
    [TEST_STEPS]: true,
  });
  const [showAverages, setShowAverages] = useState(false);
  // const [showComparisonTimeframe, setShowComparisonTimeframe] = useState(false);

  const chartClickCallback = useCallback(
    (label) => {
      const user = users.find((u) => u.name === label);
      if (user) {
        const nextParams = { ...queryParams, [USER_ID]: user.id };
        delete nextParams[TEAM_ID];
        delete nextParams[PROJECT_ID];
        const search = queryString.stringify(nextParams);
        window.history.pushState({}, "", `?${search}`);
      }
    },
    [users, queryParams]
  );

  const datasets = useMemo(() => {
    const updatedBugs = {
      label: `Updated Bugs${showAverages ? " Per Hour" : ""}`,
      backgroundColor: "#16B3F7",
      data: bugs.map((item) => {
        return showAverages ? item.hourly_updated_bugs : item.updated_bugs;
      }),
      stack: "Stack 0",
      yAxisID: BUGS,
      borderWidth: 0,
    };

    const newBugs = {
      label: `New Bugs${showAverages ? " Per Hour" : ""}`,
      backgroundColor: "#4574EC",
      data: bugs.map((item) => {
        return showAverages ? item.hourly_bugs : item.new_bugs;
      }),
      stack: "Stack 0",
      yAxisID: BUGS,
      borderWidth: 0,
    };

    const testSteps = {
      label: `Test Steps${showAverages ? " Per Hour" : ""}`,
      backgroundColor: "#F7B416",
      data: bugs.map((item) => {
        return showAverages ? item.hourly_test_steps : item.test_steps;
      }),
      stack: "Stack 1",
      yAxisID: TEST_STEPS,
      borderWidth: 0,
    };

    const filteredDatasets = [
      dataToShow[BUGS] && updatedBugs,
      dataToShow[BUGS] && newBugs,
      dataToShow[TEST_STEPS] && testSteps,
    ].filter(Boolean);

    return {
      labels: bugs.map((item) => item.label),
      datasets: filteredDatasets,
    };
  }, [bugs, dataToShow, showAverages]);

  const chartOptions = useMemo(() => {
    const xAxis = {
      linear: true,
      stacked: true,
      grid: { drawTicks: false, drawOnChartArea: false },
      ticks: {
        source: "auto",
        autoSkip: true,
        padding: 8,
        font: {
          color: "#242424",
          size: 14,
          family: "Arial",
          weight: 400,
        },
        callback: labelFormatter,
      },
    };

    const TEST_STEPS_ONLY = dataToShow[TEST_STEPS] && !dataToShow[BUGS];

    const ticks = {
      autoSkip: true,
      maxTicksLimit: 6,
      font: {
        size: 14,
        family: "Arial",
        weight: 700,
      },
    };

    const bugsAxis = {
      id: BUGS,
      position: "left",
      scalePositionLeft: true,
      stacked: true,
      grid: { drawTicks: false },
      border: {
        width: 3,
        color: "#4574EC",
      },
      ticks: {
        ...ticks,
        color: "#4574EC",
        padding: 14,
      },
    };

    const stepsAxis = {
      id: TEST_STEPS,
      position: TEST_STEPS_ONLY ? "left" : "right",
      scalePositionLeft: TEST_STEPS_ONLY,
      grid: { display: false },
      border: {
        width: 3,
        color: "#F7B416",
      },
      ticks: {
        ...ticks,
        color: "#F7B416",
        padding: TEST_STEPS_ONLY ? 14 : 5,
      },
    };

    const axes = {
      x: xAxis,
      ...(dataToShow[BUGS] ? { [BUGS]: bugsAxis } : {}),
      ...(dataToShow[TEST_STEPS] ? { [TEST_STEPS]: stepsAxis } : {}),
    };

    return {
      /* eslint-disable */
      onClick: function (evt, activeElements) {
        const position = evt.x;
        const value = this.scales.x.getValueForPixel(position);
        const label = this.scales.x.getLabelForValue(value);
        if (!!label && !!activeElements && activeElements.length > 0) {
          chartClickCallback(label);
        }
      },
      onHover: function (evt, activeElements) {
        const position = evt.x;
        const value = this.scales.x.getValueForPixel(position);
        const label = this.scales.x.getLabelForValue(value);
        const user = users.find((u) => u.name === label);
        if (!!activeElements && activeElements.length > 0 && !!user) {
          evt.native.target.style.cursor = "pointer";
        } else {
          evt.native.target.style.cursor = "default";
        }
      },
      /* eslint-enable */
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 0.5,
      categoryPercentage: 0.5,
      interaction: {
        mode: "index",
        axis: "x",
        intersect: true,
      },
      plugins: {
        legend: {
          display: true,
          align: "center",
          position: "bottom",
          labels: {
            boxWidth: 6,
            boxHeight: 6,
            padding: 10,
            usePointStyle: true,
            font: {
              size: 14,
              family: "Arial",
              weight: 400,
            },
          },
        },
      },
      scales: {
        ...axes,
      },
    };
  }, [dataToShow, chartClickCallback, users]);

  const toggleOptions = useMemo(() => {
    return [
      {
        key: ALL,
        label: "All",
        icon: (
          <CargoIcon
            height="22px"
            width="22px"
            color={
              dataToShow[BUGS] === true && dataToShow[TEST_STEPS] === true
                ? "#FFFFFF"
                : "#519ACC"
            }
          />
        ),
      },
      {
        key: BUGS,
        label: "Bugs",
        icon: (
          <BugsOutline
            height="23px"
            width="17px"
            color={
              dataToShow[BUGS] === true && dataToShow[TEST_STEPS] === false
                ? "#FFFFFF"
                : "#519ACC"
            }
          />
        ),
      },
      {
        key: TEST_STEPS,
        label: "Test Steps",
        icon: (
          <ChecklistIcon
            height="23px"
            width="19px"
            color={
              dataToShow[BUGS] === false && dataToShow[TEST_STEPS] === true
                ? "#FFFFFF"
                : "#519ACC"
            }
          />
        ),
      },
    ];
  }, [dataToShow]);

  const onDataModeSelect = (key) => {
    const nextDataMode = {
      [BUGS]: key !== TEST_STEPS,
      [TEST_STEPS]: key !== BUGS,
    };
    setDataToShow(nextDataMode);
  };

  return (
    <BugStepsDualBreakdownContainer>
      <ControlsRow>
        <PillboxToggles
          options={toggleOptions}
          onSelect={(key) => {
            onDataModeSelect(key);
          }}
        />
        <AveragesToggle>
          <ToggleSwitch
            handleChange={() => setShowAverages(!showAverages)}
            checked={showAverages}
            width={57}
            height={22}
          />
          <label>Averages</label>
        </AveragesToggle>
      </ControlsRow>
      {(!loading && bugs.length === 0) || error ? (
        <NoDataContainer>
          <EmptyEOD />
          <NoDataText>No EOD Report Data Found</NoDataText>
          <Description>
            Please verify that there are valid EOD Report submissions for the
            selected {parentFilterValue}, select a different time range, or
            choose another filter.
          </Description>
        </NoDataContainer>
      ) : (
        <ChartContainer>
          {loading && (
            <LoadingContainer>
              <LoadingSpinner loading={loading} size={48} />
            </LoadingContainer>
          )}
          <ChartInnerContainer $loading={loading}>
            <Bar
              id="bar-chart"
              datasetIdKey="bugs"
              options={chartOptions}
              data={datasets}
              plugins={[...chartPlugins]}
            />
          </ChartInnerContainer>
        </ChartContainer>
      )}
    </BugStepsDualBreakdownContainer>
  );
};

const BugStepsDualBreakdownContainer = styled.div`
  margin-top: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ControlsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const AveragesToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  label {
    font-family: Arial;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }
`;

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  height: 450px;
  display: flex;
  justify-content: center;
`;

export const ChartInnerContainer = styled.div`
  filter: ${(props) => (props.$loading ? "blur(1rem)" : "none")};
  transition: filter 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  z-index: 1;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding: 20px;
  height: 294px;
  margin-top: 32px;
  border-left: 2px solid #4574ec;
  border-right: 2px solid #efb035;
`;

const NoDataText = styled.p`
  color: #000;
  text-align: center;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 13px 0px;
`;

const Description = styled.p`
  color: #000;
  text-align: center;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

BugStepsDualBreakdown.propTypes = {
  bugs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      count: PropTypes.number,
      new_bugs: PropTypes.number,
      updated_bugs: PropTypes.number,
      test_steps: PropTypes.number,
      test_runs: PropTypes.number,
      hourly_bugs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      hourly_updated_bugs: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      hourly_test_steps: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    })
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      role: PropTypes.string,
      avatar: PropTypes.string,
      teams: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  loading: PropTypes.bool,
  parentFilterValue: PropTypes.string.isRequired,
  queryParams: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    project_id: PropTypes.string,
    team_id: PropTypes.string,
  }),
};

BugStepsDualBreakdown.defaultProps = {
  bugs: [
    // dummy data, purely for the initial loading state
    {
      label: "2023/05/01",
      count: 56,
      new_bugs: 120,
      updated_bugs: 52,
      test_steps: 2003,
      test_runs: 77,
    },
    {
      label: "2023/05/02",
      count: 66,
      new_bugs: 167,
      updated_bugs: 100,
      test_steps: 3840,
      test_runs: 69,
    },
    {
      label: "2023/05/03",
      count: 64,
      new_bugs: 97,
      updated_bugs: 64,
      test_steps: 2906,
      test_runs: 105,
    },
    {
      label: "2023/05/04",
      count: 58,
      new_bugs: 109,
      updated_bugs: 67,
      test_steps: 2612,
      test_runs: 70,
    },
    {
      label: "2023/05/05",
      count: 58,
      new_bugs: 99,
      updated_bugs: 83,
      test_steps: 1974,
      test_runs: 53,
    },
    {
      label: "2023/05/08",
      count: 53,
      new_bugs: 111,
      updated_bugs: 99,
      test_steps: 2530,
      test_runs: 53,
    },
    {
      label: "2023/05/09",
      count: 61,
      new_bugs: 115,
      updated_bugs: 75,
      test_steps: 2379,
      test_runs: 51,
    },
    {
      label: "2023/05/10",
      count: 60,
      new_bugs: 117,
      updated_bugs: 95,
      test_steps: 3269,
      test_runs: 101,
    },
    {
      label: "2023/05/11",
      count: 68,
      new_bugs: 122,
      updated_bugs: 51,
      test_steps: 3690,
      test_runs: 101,
    },
    {
      label: "2023/05/12",
      count: 60,
      new_bugs: 84,
      updated_bugs: 61,
      test_steps: 3122,
      test_runs: 55,
    },
  ],
  loading: false,
};

export default BugStepsDualBreakdown;
