import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  BarElement,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import {
  ChartInnerContainer,
  LoadingContainer,
} from "./BugsStepsDualBreakdown";
import LoadingSpinner from "../../common/loading_spinner";
import { bugsTypes } from "./ProfileBreakdown"; // TODO: move to types, organize these better
import chartPlugins, { labelFormatter } from "./chartPlugins";

ChartJS.register(CategoryScale, BarElement, LinearScale, Tooltip, Legend);

const BugsBreakdown = (props) => {
  const { bugs, previousBugs, showPreviousBugs, loading } = props;

  const datasets = useMemo(() => {
    const previousNewBugs = {
      label: "Previous Bugs (new)",
      backgroundColor: "rgb(145,171,238)",
      data: previousBugs?.map((item) => item.new_bugs) || [],
      borderWidth: 0,
    };

    const previousUpdatedBugs = {
      label: "Previous Bugs (updated)",
      backgroundColor: "rgb(161,216,248)",
      data: previousBugs?.map((item) => item.updated_bugs) || [],
      borderWidth: 0,
    };

    const newBugs = {
      label: "New Bugs",
      backgroundColor: "#4574EC",
      data: bugs.map((item) => item.new_bugs),
      borderWidth: 0,
    };

    const updatedBugs = {
      label: "Updated Bugs",
      backgroundColor: "#16B3F7",
      data: bugs.map((item) => item.updated_bugs),
      borderWidth: 0,
    };

    const labels = bugs.map((item, idx) => {
      if (!showPreviousBugs) return item.label;
      return `${item.label} / ${previousBugs[idx].label}`;
    });

    return {
      labels,
      datasets: [
        newBugs,
        updatedBugs,
        showPreviousBugs && previousNewBugs,
        showPreviousBugs && previousUpdatedBugs,
      ].filter(Boolean),
    };
  }, [bugs, previousBugs, showPreviousBugs]);

  const chartOptions = useMemo(() => {
    const xAxis = {
      linear: true,
      stacked: false,
      grid: {
        drawTicks: false,
        drawOnChartArea: false,
      },
      ticks: {
        source: "auto",
        autoSkip: true,
        padding: 16,
        font: {
          color: "#242424",
          size: 14,
          family: "Arial",
          weight: 400,
        },
        callback: labelFormatter,
      },
    };

    const yAxis = {
      position: "left",
      scalePositionLeft: true,
      stacked: false,
      grid: { drawTicks: false },
      border: {
        width: 3,
        color: "#4574EC",
      },
      ticks: {
        autoSkip: true,
        maxTicksLimit: 6,
        font: {
          size: 14,
          family: "Arial",
          weight: 700,
        },
        color: "#4574EC",
        padding: 14,
      },
    };

    const axes = {
      x: xAxis,
      y: yAxis,
    };

    return {
      responsive: true,
      maintainAspectRatio: false,
      barPercentage: 1,
      categoryPercentage: 0.5,
      interaction: {
        intersect: true,
      },
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          align: "center",
          labels: {
            boxWidth: 6,
            boxHeight: 6,
            padding: bugs.length === 0 ? 40 : 10,
            usePointStyle: true,
            font: {
              size: 14,
              family: "Arial",
              weight: 400,
            },
          },
        },
      },
      scales: {
        ...axes,
      },
    };
  }, [bugs]);

  return (
    <ChartContainer>
      <ChartInnerContainer $loading={loading}>
        <Bar
          datasetIdKey="bugs_breakdown"
          data={datasets}
          options={chartOptions}
          plugins={chartPlugins}
        />
      </ChartInnerContainer>
      {loading && (
        <LoadingContainer>
          <LoadingSpinner loading={loading} size={48} />
        </LoadingContainer>
      )}
    </ChartContainer>
  );
};

const ChartContainer = styled.div`
  position: relative;
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
`;

BugsBreakdown.propTypes = {
  bugs: PropTypes.arrayOf(bugsTypes),
  previousBugs: PropTypes.arrayOf(bugsTypes),
  showPreviousBugs: PropTypes.bool,
  loading: PropTypes.bool,
};

BugsBreakdown.defaultProps = {
  bugs: [
    {
      count: 1,
      label: "2023/06/01",
      new_bugs: 8,
      updated_bugs: 2,
    },
    {
      count: 2,
      label: "2023/06/02",
      new_bugs: 6,
      updated_bugs: 1,
    },
    {
      count: 1,
      label: "2023/06/06",
      new_bugs: 3,
      updated_bugs: 6,
    },
    {
      count: 1,
      label: "2023/06/07",
      new_bugs: 0,
      updated_bugs: 4,
    },
  ],
};

export default BugsBreakdown;
