import React, { useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  PerformancePageLayout,
  PerformanceTitleRow,
  PerformanceTitle,
  PerformanceBodyProfile,
  HeaderIconContainer,
  SplitWidget,
  SplitWidgetRow,
  DashboardWidget,
  NavigationTitle,
  NavigationContainer,
  NavigationLink,
} from "./styles";
import ProfileContext from "./ProfileContext";
import PerformanceHeaderIcon from "./Components/PerformanceHeaderIcon";
import usePerformanceProfileData from "../../hooks/data/usePerformanceProfileData";
import ProfileUserInfo from "./Widgets/ProfileUserInfo";
import ProfileBreakdown from "./Widgets/ProfileBreakdown";
import AssessmentPerformance from "./Widgets/AssessmentPerformance";
import AssessmentHistory from "./Components/AssessmentHistory";
import EODHistory from "./Components/EODHistory";
import BackArrowIcon from "../icons/performance_bio/BackArrow";
import { userTypes } from "../../types";

const PerformanceProfile = (props) => {
  const { projects, current_user } = props;
  const {
    data,
    loading,
    error,
    queryParams,
    setProfileData,
    extendedProfileView,
  } = usePerformanceProfileData(current_user);

  const {
    bugs,
    eod_reports,
    hours,
    // prevous_period_breakdowns,
    metrics,
    user,
    assessments,
    assessment_performance,
  } = data;

  const metExpertiseCategories = assessment_performance
    ?.filter((category) => category.percentage === 1)
    .map((category) => category.category);

  const ProfileContextValue = useMemo(() => {
    return {
      bugs,
      eod_reports,
      hours,
      metrics,
      user,
      loading,
      queryParams,
      error,
      projects,
      current_user,
      setProfileData,
      assessments,
      assessment_performance,
    };
  }, [
    bugs,
    eod_reports,
    hours,
    metrics,
    user,
    loading,
    queryParams,
    error,
    projects,
    current_user,
    setProfileData,
    assessments,
    assessment_performance,
  ]);

  return (
    <ProfileContext.Provider value={ProfileContextValue}>
      <PerformancePageLayout>
        <PerformanceTitleRow>
          <HeaderIconContainer>
            <PerformanceHeaderIcon />
          </HeaderIconContainer>
          <PerformanceTitle>Performance</PerformanceTitle>
        </PerformanceTitleRow>
        <PerformanceBodyProfile>
          {current_user.role === "admin" && (
            <NavigationContainer>
              <NavigationTitle>
                <NavigationLink href="/performance">
                  <BackArrowIcon />
                </NavigationLink>
                Performance Dashboard
              </NavigationTitle>
            </NavigationContainer>
          )}
          <SplitWidgetRow>
            <ResizedSplitWidget>
              <ProfileUserInfo
                user={user}
                metExpertiseCategories={metExpertiseCategories}
              />
            </ResizedSplitWidget>
            <FreeSplitWidget>
              <AssessmentPerformance data={assessment_performance || []} />
            </FreeSplitWidget>
          </SplitWidgetRow>
          {extendedProfileView && (
            <DashboardWidget>
              <ProfileBreakdown
                projects={projects}
                data={data}
                loading={loading}
                queryParams={queryParams}
              />
            </DashboardWidget>
          )}
          {current_user.role === "admin" && (
            <>
              <ResizedDashboardWidget>
                <AssessmentHistory assessments={assessments} />
              </ResizedDashboardWidget>
              <ResizedDashboardWidget>
                <EODHistory reports={eod_reports} />
              </ResizedDashboardWidget>
            </>
          )}
        </PerformanceBodyProfile>
      </PerformancePageLayout>
    </ProfileContext.Provider>
  );
};

const ResizedSplitWidget = styled(SplitWidget)`
  flex-basis: 120%;
  padding: 0px;
`;

const FreeSplitWidget = styled(SplitWidget)`
  flex-basis: unset;
  padding: unset;
`;

const ResizedDashboardWidget = styled(DashboardWidget)`
  height: 595px;
`;

PerformanceProfile.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      identifier: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  current_user: userTypes,
};

export default PerformanceProfile;
