import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const MetricIndicator = (props) => {
  const { icon, value, label, delta, className } = props;

  return (
    <MetricIndicatorContainer className={className}>
      <MetricBody>
        {icon && <IconContainer>{icon}</IconContainer>}
        <ValueContainer>
          <Value>{value || value === 0 ? value : "--"}</Value>
          <Label>{label}</Label>
        </ValueContainer>
        {(delta || delta === 0) && (
          <DeltaContainer>
            {delta !== 0 && <DeltaIcon $positive={delta > 0}>▲</DeltaIcon>}
            <Delta $positive={delta > 0}>{delta === 0 ? "--" : delta}</Delta>
          </DeltaContainer>
        )}
      </MetricBody>
    </MetricIndicatorContainer>
  );
};

const MetricIndicatorContainer = styled.div`
  flex-basis: calc(100% / 3);
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  display: flex;
  align-items: center;
`;

const MetricBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  padding: 24px;
`;

const IconContainer = styled.div`
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Value = styled.div`
  color: #242424;
  font-size: 20px;
  line-height: 20px;
  font-family: "Manrope";
  font-weight: 700;
`;

const Label = styled.div`
  font-size: 14px;
  line-height: 14px;
  font-family: Arial;
  font-weight: 400;
  color: #242424;
`;

const DeltaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const Delta = styled.div`
  color: #242424;
  font-size: 20px;
  line-height: 20px;
  font-family: "Manrope";
  font-weight: 700;
  color: ${(props) => (props.$positive ? "#0DC958" : "#ED4949")};
`;

const DeltaIcon = styled.span`
  color: ${(props) => (props.$positive ? "#0DC958" : "#ED4949")};
  transform: rotate(${(props) => (props.$positive ? "0deg" : "180deg")});
`;

MetricIndicator.propTypes = {
  icon: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string.isRequired,
  delta: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
};

export default MetricIndicator;
