import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-use";
import queryString from "query-string";
import _ from "lodash";
import ApiClient from "../../utils/ApiClient";

const usePerformanceProfileData = (current_user) => {
  const [profileData, setProfileData] = useState({});
  console.log(profileData);
  const [queryParams, setQueryParams] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();

  const userId = useMemo(() => {
    return location.pathname.split("/")[2];
  }, [location.pathname]);

  const { start_date, end_date, project_id, previous_period } =
    queryString.parse(location.search);

  const profileFilterParams = useMemo(() => {
    return {
      start_date,
      end_date,
      project_id,
      previous_period,
    };
  }, [start_date, end_date, project_id, previous_period]);

  const extendedProfileView = useMemo(() => {
    return (
      current_user.role === "admin" ||
      (current_user.role === "client" &&
        current_user.additional_permissions.includes(
          "Client Performance Viewable"
        ))
    );
  }, [current_user]);

  useEffect(() => {
    const api = new ApiClient();

    const fetchDashboardData = async () => {
      if (!userId) return null;

      try {
        setLoading(true);
        setError(null);

        await api
          .get(
            `/performance/${userId}/profile.json?${queryString.stringify(
              profileFilterParams
            )}`
          )
          .then((res) => {
            setProfileData(
              _.pick(res.data, [
                "bugs",
                "hours",
                "previous_period_breakdowns",
                "metrics",
                "assessments",
                "assessment_performance",
                "eod_reports",
                "user",
              ])
            );
            setQueryParams(res.data.query_params);
          });
      } catch (err) {
        console.error(err);
        setError(err);
      } finally {
        setLoading(false);
      }

      return null;
    };

    fetchDashboardData();
  }, [userId, profileFilterParams]);

  return {
    data: profileData,
    loading,
    error,
    queryParams,
    setProfileData,
    extendedProfileView,
  };
};

export default usePerformanceProfileData;
