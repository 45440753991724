import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _ from "lodash";
import queryString from "query-string";
import { WidgetTitle, TitleRow, TitleSection } from "../styles";
import PerformanceBreakdownIcon from "../Components/PerformanceBreakdownIcon";
import BugsMetrics from "./BugsMetrics";
import TestStepMetrics from "./TestStepMetrics";
import { BugsBreakdown, TestStepBreakdown } from ".";
import PerformanceProjectDropdown from "../Components/PerformanceProjectDropdown";
import PerformanceDateRange from "../Components/PerformanceDateRange";
import ToggleSwitch from "../../common/toggle_switch";
import { PROJECT_ID } from "../constants";

const ProfileBreakdown = (props) => {
  const { data, projects, loading, queryParams } = props;

  const { bugs, previous_period_breakdowns, metrics } = data;

  const showComparisonTimespan = useMemo(() => {
    return queryParams.previous_period === "true";
  }, [queryParams]);

  const dateString = useMemo(() => {
    let d = "";
    if (metrics?.timeframe) {
      d += `${metrics.timeframe.adverb}: `;
    }

    if (queryParams.start_date && queryParams.end_date) {
      d += `${queryParams.start_date} - ${queryParams.end_date}`;
    }

    if (showComparisonTimespan && previous_period_breakdowns) {
      const { start_date, end_date } = previous_period_breakdowns.endpoints;
      d += ` vs ${start_date} - ${end_date}`;
    }

    return d;
  }, [
    queryParams,
    showComparisonTimespan,
    previous_period_breakdowns,
    metrics,
  ]);

  const workString = useMemo(() => {
    if (!metrics) return "";
    let d = `Work Days Represented: ${metrics.count}`;

    if (showComparisonTimespan && previous_period_breakdowns.metrics) {
      d += ` vs ${previous_period_breakdowns.metrics.count}`;
    }

    return d;
  }, [metrics, previous_period_breakdowns, showComparisonTimespan]);

  const handleProjectSelect = useCallback(
    (id) => {
      const nextParams = {
        ...queryParams,
        [PROJECT_ID]: id || null,
      };
      const nextParamsWithoutNulls = _.omitBy(nextParams, _.isNull);
      const search = queryString.stringify(nextParamsWithoutNulls);
      window.history.pushState({}, "", `?${search}`);
    },
    [queryParams]
  );

  const handleDateRangeSelect = useCallback(
    (start_date, end_date) => {
      const nextParams = {
        ...queryParams,
        start_date,
        end_date,
      };
      const search = queryString.stringify(nextParams);
      window.history.pushState({}, "", `?${search}`);
    },
    [queryParams]
  );

  const handleSelectComparisonTimeframe = useCallback(() => {
    const nextParams = {
      ...queryParams,
      previous_period: showComparisonTimespan === true ? null : "true", // set search param to null if currently true
    };
    const nextParamsWithoutNulls = _.omitBy(nextParams, _.isNull);
    const search = queryString.stringify(nextParamsWithoutNulls);
    window.history.pushState({}, "", `?${search}`);
  }, [queryParams, showComparisonTimespan]);

  return (
    <ProfileBreakdownContainer>
      <TitleRow>
        <TitleSection>
          <PerformanceBreakdownIcon />
          <WidgetTitle>Performance Breakdown</WidgetTitle>
        </TitleSection>
        <FiltersSection>
          <PerformanceProjectDropdown
            projectsList={projects}
            queryParams={queryParams}
            onProjectSelect={handleProjectSelect}
          />
          <PerformanceDateRange
            queryParams={queryParams}
            onDateRangeChange={handleDateRangeSelect}
          />
        </FiltersSection>
      </TitleRow>
      <BugsMetrics
        bugs={bugs}
        previousBugs={previous_period_breakdowns?.bugs}
        metrics={metrics}
        previousMetrics={previous_period_breakdowns?.metrics}
        showDeltas={showComparisonTimespan}
      />
      <ChartWrapper>
        <ChartTitleRow>
          <ChartExplain>
            <ChartTitle>{dateString}</ChartTitle>
            <WorkDays>{workString}</WorkDays>
          </ChartExplain>
          <ComparisonToggle>
            <ToggleSwitch
              handleChange={handleSelectComparisonTimeframe}
              checked={showComparisonTimespan}
            />
            <ComparisonLabel>
              Compare Previous{" "}
              {metrics?.timeframe ? metrics.timeframe.plural : ""}
            </ComparisonLabel>
          </ComparisonToggle>
        </ChartTitleRow>
        <BugsBreakdown
          bugs={bugs}
          previousBugs={previous_period_breakdowns?.bugs}
          showPreviousBugs={showComparisonTimespan}
          loading={loading}
        />
      </ChartWrapper>
      <TestStepMetrics
        bugs={bugs}
        previousBugs={previous_period_breakdowns?.bugs}
        metrics={metrics}
        previousMetrics={previous_period_breakdowns?.metrics}
        showDeltas={showComparisonTimespan}
      />
      <ChartWrapper>
        <ChartTitle>
          <ChartExplain>
            <ChartTitle>{dateString}</ChartTitle>
            <WorkDays>{workString}</WorkDays>
          </ChartExplain>
        </ChartTitle>
        <TestStepBreakdown
          bugs={bugs}
          previousBugs={previous_period_breakdowns?.bugs}
          showPreviousBugs={showComparisonTimespan}
          loading={loading}
        />
      </ChartWrapper>
    </ProfileBreakdownContainer>
  );
};

const ProfileBreakdownContainer = styled.div``;

const FiltersSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ChartWrapper = styled.div`
  width: 100%;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  padding: 24px 36px;
  margin-top: 12px;
`;

const ChartTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ChartExplain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const WorkDays = styled.div`
  font-family: Arial;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
`;

const ChartTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-right: 10px;
`;

const ComparisonToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ComparisonLabel = styled.div`
  font-size: 13px;
  font-weight: 400;
  color: #000;
`;

export const bugsTypes = PropTypes.shape({
  count: PropTypes.number,
  label: PropTypes.string,
  new_bugs: PropTypes.number,
  updated_bugs: PropTypes.number,
  test_steps: PropTypes.number,
  test_runs: PropTypes.number,
});

export const metricsTypes = PropTypes.shape({
  count: PropTypes.number,
  new_bugs: PropTypes.number,
  updated_bugs: PropTypes.number,
  hourly_bugs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hours: PropTypes.number,
  test_steps: PropTypes.number,
  hourly_test_steps: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  test_runs: PropTypes.number,
  timeframe: PropTypes.shape({
    adverb: PropTypes.string,
    noun: PropTypes.string,
    plural: PropTypes.string,
    plural_scope: PropTypes.string,
    scope: PropTypes.string,
  }),
});

ProfileBreakdown.propTypes = {
  data: PropTypes.shape({
    bugs: PropTypes.arrayOf(bugsTypes),
    metrics: metricsTypes,
    previous_period_breakdowns: PropTypes.shape({
      bugs: PropTypes.arrayOf(bugsTypes),
      metrics: metricsTypes,
      endpoints: PropTypes.shape({
        start_date: PropTypes.string,
        end_date: PropTypes.string,
      }),
    }),
  }),
  loading: PropTypes.bool,
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      identifier: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  queryParams: PropTypes.shape({
    id: PropTypes.string,
    start_date: PropTypes.string,
    end_date: PropTypes.string,
    project_id: PropTypes.string,
    team_id: PropTypes.string,
    previous_period: PropTypes.string,
  }),
};

export default ProfileBreakdown;
