import React, { useState, useMemo, useEffect } from "react";
import styled from "styled-components";
import { WidgetTitle } from "../styles";
import PropTypes from "prop-types";
import Table from "rc-table";
import moment from "moment";
import RoundDropdown from "../../icons/round_dropdown";
import TextHistoryIcon from "../../icons/performance_bio/TextHistory";

const [LEVEL, RESOURCE, STARTED_AT, PASSED, SCORE] = [
  "level",
  "resource",
  "started_at",
  "passed",
  "score",
];

const [ASC, DESC] = ["asc", "desc"];

const AssessmentHistory = (props) => {
  const [assessments, setAssessments] = useState([]);
  const [sortKey, setSortKey] = useState(LEVEL);
  const [sortOrder, setSortOrder] = useState(DESC);

  useEffect(() => {
    if (props.assessments) {
      const formattedAssessments = props.assessments.data.map((assessment) => {
        if (assessment.started_at === null) {
          return {
            ...assessment,
            started_at: "-",
            passed: "Invited",
          };
        }
        const formattedDateTime = moment(assessment.started_at).format(
          "MM/DD/YYYY - hh:mmA z"
        );
        const passedString = assessment.passed ? "Pass" : "Fail";
        return {
          ...assessment,
          started_at: formattedDateTime,
          passed: passedString,
        };
      });
      setAssessments(formattedAssessments);
    }
  }, [props.assessments]);

  const sortedAssessments = useMemo(() => {
    const sorted = [...assessments].sort((a, b) => {
      const SORT_MAPS = {
        [LEVEL]: (a, b) => b.level - a.level,
        [RESOURCE]: (a, b) => a.resource.localeCompare(b.resource),
        [STARTED_AT]: (a, b) =>
          moment(a.started_at, "MM/DD/YYYY - hh:mmA").diff(
            moment(b.started_at, "MM/DD/YYYY - hh:mmA")
          ),
        [PASSED]: (a, b) => a.passed.localeCompare(b.passed),
        [SCORE]: (a, b) => a.correct_responses - b.correct_responses,
      };
      if (SORT_MAPS[sortKey] === undefined) {
        return 0;
      }
      const value = SORT_MAPS[sortKey](a, b);
      return value * (sortOrder === ASC ? 1 : -1);
    });

    return sorted;
  }, [assessments, sortOrder, sortKey]);

  const setSorter = (key) => {
    if (key === sortKey) {
      setSortOrder((prevSortOrder) => (prevSortOrder === ASC ? DESC : ASC));
    } else {
      setSortKey(key);
      setSortOrder(DESC);
    }
  };

  const columns = [
    {
      title: (
        <ColumnHeaderButton onClick={() => setSorter(LEVEL)} $align="center">
          Level
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === LEVEL}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [LEVEL],
      width: 140,
      className: "wiki-assessments-column wiki-assessments-level",
      render: (text, record) => {
        return (
          <LevelButton $isPublished={record.published}>
            Level {text}
          </LevelButton>
        );
      },
    },
    {
      title: (
        <ColumnHeaderButton onClick={() => setSorter(RESOURCE)}>
          Resource
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === RESOURCE}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [RESOURCE],
      className: "wiki-assessments-column wiki-assessments-resource",
      render: (text, record) => {
        return (
          <ResourceLink href={`/wiki/${record.wiki_resource_id}`}>
            {text}
          </ResourceLink>
        );
      },
    },
    {
      title: (
        <ColumnHeaderButton onClick={() => setSorter(STARTED_AT)}>
          Attempt Date
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === STARTED_AT}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: "started_at",
      className: "wiki-assessments-column wiki-assessments-created-by",
      render: (text) => {
        return <TableText>{text}</TableText>;
      },
    },

    {
      title: (
        <ColumnHeaderButton onClick={() => setSorter(PASSED)}>
          Result
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === PASSED}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: "passed",
      className: "wiki-assessments-column wiki-assessments-result",
      render: (text, record) => {
        return (
          <TableText $pass={record.passed === "Pass"}>
            {record.passed}
          </TableText>
        );
      },
    },
    {
      title: (
        <ColumnHeaderButton onClick={() => setSorter(SCORE)}>
          Score
          <IconContainer
            $rotate={sortOrder === ASC}
            $isVisible={sortKey === SCORE}
          >
            <RoundDropdown color="#fff" height="16px" width="16px" />
          </IconContainer>
        </ColumnHeaderButton>
      ),
      dataIndex: [SCORE],
      className: "wiki-assessments-column wiki-assessments-score",
      render: (text, record) => {
        return (
          <TableText>
            {record.correct_responses + " / " + record.total_questions}
          </TableText>
        );
      },
    },
  ];

  return (
    <AssessmentContainer>
      <WidgetContainer>
        <IconWrapper>
          <TextHistoryIcon />
          <WidgetTitle style={{ marginLeft: "15px" }}>
            Assessment History
          </WidgetTitle>
        </IconWrapper>
      </WidgetContainer>
      <TableContainer>
        <Table
          className="performance-assessments-table"
          data={sortedAssessments}
          columns={columns}
          rowKey="id"
          scroll={{ y: 400 }}
        />
      </TableContainer>
    </AssessmentContainer>
  );
};

const AssessmentContainer = styled.div`
  height: 100%;
`;

const WidgetContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
`;
const TableContainer = styled.div`
  width: 100%;
  padding: 8px 15px;
  margin-top: 23px;
  height: 100%;
  background-color: #ededed;
  height: 90%;
  flex-grow: 1;
  overflow-y: auto;
`;
const TableText = styled.span`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: ${(props) => (props.$pass ? "#01b147" : "#000")};
`;

const ResourceLink = styled.a`
  font-size: 14px;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
`;

const ColumnHeaderButton = styled.button`
  background: none;
  border: none;
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  padding: 0;
  justify-content: flex-start;
  width: 100%;
`;

const IconContainer = styled.div`
  margin-left: 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: transform, opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 0.2s;
  ${({ $rotate }) => $rotate && `transform: rotate(180deg);`}
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
`;

const LevelButton = styled.div`
  width: 100%;
  max-width: 100px;
  height: 28px;
  background-color: ${(props) => (props.$isPublished ? "#519acc" : "#D7D7D7")};
  color: ${(props) => (props.$isPublished ? "#ffffff" : "#000000")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
`;

AssessmentHistory.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.string,
      resource: PropTypes.string,
      started_at: PropTypes.string,
      passed: PropTypes.string,
      score: PropTypes.number,
    })
  ),
};

export default AssessmentHistory;
