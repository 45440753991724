import React, { useMemo, useCallback } from "react";
import ChecklistIcon from "@icons/checklist_icon";
import styled from "styled-components";
import MetricIndicator from "../Components/MetricIndicator";

const TestStepMetrics = (props) => {
  const { bugs, previousBugs, metrics, previousMetrics, showDeltas } = props;

  const getTimeframeAverage = useCallback((dataset) => {
    if (!dataset) return null;
    return (
      dataset.reduce((acc, item) => acc + item.test_steps, 0) / dataset.length
    ).toFixed(2);
  }, []);

  const deltas = useMemo(() => {
    if (!showDeltas || !previousMetrics || !metrics) return {};

    return {
      test_steps: metrics.test_steps - previousMetrics.test_steps,
      test_runs: metrics.test_runs - previousMetrics.test_runs,
      hourly_average: (
        parseFloat(metrics.hourly_test_steps) -
        parseFloat(previousMetrics.hourly_test_steps)
      ).toFixed(2),
      average: (
        getTimeframeAverage(bugs) - getTimeframeAverage(previousBugs || 0)
      ).toFixed(2),
    };
  }, [
    showDeltas,
    previousMetrics,
    metrics,
    getTimeframeAverage,
    bugs,
    previousBugs,
  ]);

  if (!metrics) return null;

  return (
    <MetricRow>
      <MetricIndicator
        label="Test Steps Completed"
        value={metrics?.test_steps}
        icon={<ChecklistIcon height="33px" width="43px" color="#FD772B" />}
        delta={showDeltas && deltas.test_steps}
      />
      <MetricIndicator
        label="Test Runs"
        value={metrics?.test_runs}
        delta={showDeltas && deltas.test_runs}
      />
      <MetricIndicator
        label="Hourly Test Step Average"
        value={metrics?.hourly_test_steps}
        delta={showDeltas && deltas.hourly_average}
      />
      <MetricIndicator
        label={
          metrics?.timeframe
            ? `${metrics.timeframe.adverb} Test Step Average`
            : "Test Step Average"
        }
        value={getTimeframeAverage(bugs)}
        delta={showDeltas && deltas.average}
      />
    </MetricRow>
  );
};

const MetricRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 6px;
  margin-top: 12px;
`;

TestStepMetrics.propTypes = {};

export default TestStepMetrics;
